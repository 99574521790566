import React from 'react'
import { Link } from 'gatsby'

import * as styles from './Footer.module.scss'
import SocialMedia from '../UI/SocialMedia/SocialMedia'

import PreciseLogo from './../../images/logos/logo-footer.webp'
import ISOLogo from './../../images/icons/ISOquality9001-footer.png'
import LocationsList from './LocationTabs/LocationsList'
import USMapFooter from '../UI/Images/USMap/USMapFooter'
import { Icon } from '@iconify/react'
import QualityAssuranceStatement from './../../pdfs/Commitment_to_Quality.pdf'
import PrivacyPolicy from './../../pdfs/Precise_Privacy_Policy.pdf'
import TermsUse from './../../pdfs/Precise_Website_Terms_of_Use.pdf'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__trim}></div>
      <article className={styles.footer__links}>
        <article className={`${styles.footer__container}`}>
          <section className={styles.footer__socialMediaWrap}>
            <img
              loading="lazy"
              className={`${styles.footer__logo}`}
              src={PreciseLogo}
              alt="Precise Logo in gray"
              width="212"
              height="124.8"
            />
            <Link to="/contact-us/" className={`${styles.footer__mobileLink}`}>
              Contact Us{' '}
              <Icon
                className={`${styles.dropdownArrow__icon}`}
                icon="eva:arrow-ios-forward-fill"
                inline={true}
              />
            </Link>
            <section className={styles.footer__socialMedia}>
              <SocialMedia color="white" />
            </section>
          </section>
          <section className={styles.footer__listWrap}>
            <section>
              <h5 className={styles.footer__header}>Careers</h5>
              <ul className={styles.footer__linkList}>
                <li className={styles.footer__link}>
                  <Link to="/careers/">
                    Why Work for Precise
                    <Icon
                      className={`${styles.dropdownArrow__icon}`}
                      icon="eva:arrow-ios-forward-fill"
                      inline={true}
                    />
                  </Link>
                </li>
                <li className={styles.footer__link}>
                  <Link to="/careers-portal/">
                    Current Openings
                    <Icon
                      className={`${styles.dropdownArrow__icon}`}
                      icon="eva:arrow-ios-forward-fill"
                      inline={true}
                    />
                  </Link>
                </li>
              </ul>
            </section>
            <section>
              <h5 className={styles.footer__header}>Company Links</h5>
              <ul className={styles.footer__linkList}>
                <li className={styles.footer__link}>
                  <a
                    href="https://goprecise.sharepoint.us/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    MyPrecise
                    <Icon
                      className={`${styles.dropdownArrow__icon}`}
                      icon="eva:arrow-ios-forward-fill"
                      inline={true}
                    />
                  </a>
                </li>
                <li className={styles.footer__link}>
                  <Link to="/suppliers/">
                    Suppliers
                    <Icon
                      className={`${styles.dropdownArrow__icon}`}
                      icon="eva:arrow-ios-forward-fill"
                      inline={true}
                    />
                  </Link>
                </li>
              </ul>
            </section>
            <section>
              <h5 className={styles.footer__header}>Connecting</h5>
              <ul className={styles.footer__linkList}>
                <li className={styles.footer__link}>
                  <Link to="/contact-us/">
                    Contact Us
                    <Icon
                      className={`${styles.dropdownArrow__icon}`}
                      icon="eva:arrow-ios-forward-fill"
                      inline={true}
                    />
                  </Link>
                </li>
              </ul>
            </section>
          </section>
        </article>
      </article>
      <article className={styles.footer__locations}>
        <article className={`${styles.footer__container}`}>
          <h5 className={styles.footer__header}>Locations</h5>
          <section className={`${styles.footer__locationsContainer} `}>
            <section className={`${styles.footer__locationMap}`}>
              <USMapFooter />
            </section>
            <section className={`${styles.footer__locationCollection}`}>
              <LocationsList />
            </section>
          </section>
        </article>
      </article>
      <article className={`${styles.footer__details}`}>
        <article className={`${styles.footer__container}`}>
          <section className={styles.qualityStatement}>
            {/* <img
              loading="lazy"
              src={ISOLogo}
              alt="ISO Quality 9001 Logo"
              width="68"
              height="auto"
            /> */}
            <p>
              <a
                rel="noreferrer"
                href={QualityAssuranceStatement}
                target="_blank"
                aria-label='Open new tab with commitment to quality statement'
              >
                Commitment to Quality
              </a>
            </p>
            <p>
              <a rel="noreferrer" href={PrivacyPolicy} target="_blank" aria-label='Open new tab with privacy policy'>
                Privacy Policy
              </a>
            </p>
            <p>
              <a rel="noreferrer" href={TermsUse} target="_blank" aria-label='Open new tab with terms of use'>
                Terms of Use
              </a>
            </p>
          </section>
          <section className={styles.rightsReserved}>
            {/* <p>© {new Date().getFullYear()} Precise Systems Inc. </p>
            <p>All Rights Reserved</p> */}
            <p className={styles.siteMap}>
              <Link to="/site-map/">Site Map</Link>
            </p>
          </section>
        </article>
      </article>
    </footer>
  )
}

export default Footer
